import { addPropertyControls, ControlType } from "framer"
import { useState, useEffect, cloneElement } from "react"
import { EmptyState } from "https://framer.com/m/Shared-8iGD.js@9bbvaZD8RsNyj0rYVQ4z"

const THEME_ATTRIBUTE = "framestack-theme"
const SIGNAL_NAME = "framestack-theme-changed"

/**
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */
export default function ThemeVariants(props) {
    const [isDarkTheme, setIsDarkTheme] = useState(null)

    useEffect(() => {
        const updateTheme = () => {
            // For compatibility with the Theme Toggle component.
            // https://Framestack.co/components/theme-toggle
            // If the site does not have a theme toggle, the device theme will be used.
            const currentToggleState =
                document.body.getAttribute(THEME_ATTRIBUTE)

            // Check if page has the theme toggle attribute.
            if (!currentToggleState || currentToggleState == "deviceTheme") {
                if (document.body.hasAttribute("data-framer-theme")) {
                    setIsDarkTheme(
                        document.body.getAttribute("data-framer-theme") ==
                            "dark"
                    )
                } else {
                    const mediaQuery = window.matchMedia(
                        "(prefers-color-scheme: dark)"
                    )
                    setIsDarkTheme(mediaQuery.matches)
                }
            } else {
                // Use device theme
                setIsDarkTheme(currentToggleState == "dark") // light or dark
            }
        }

        // Event listener for changes in the device theme
        const osThemeChange = (e) => {
            setIsDarkTheme(e.matches)
        }

        // Update the theme when the page loads
        updateTheme()

        // Listen for theme changes made by the theme toggle component
        window.addEventListener(SIGNAL_NAME, updateTheme)

        // Listen for changes in the device theme
        const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
        mediaQuery.addListener(osThemeChange)

        // Clean up the event listeners when the theme toggle component unmounts
        return () => {
            window.removeEventListener(SIGNAL_NAME, updateTheme)
            mediaQuery.removeListener(osThemeChange)
        }
    }, [])

    // Before the theme has been determined, render nothing.
    // This prevents flashing while the page loads.
    if (isDarkTheme === null) {
        return null
    }

    // Empty state
    if (!props.light?.[0] || !props.dark?.[0]) {
        return (
            <EmptyState
                title="Add dark and light layers."
                subtitle="Use the properties panel on the right side to connect layers for dark and light theme."
            />
        )
    }

    let layer = isDarkTheme ? props.dark?.[0] : props.light?.[0]
    if (layer && (props.style?.width || props.style?.height)) {
        layer = cloneElement(layer, {
            style: {
                ...layer.props.style,
                ...props.style,
            },
        })
    }

    if (!layer) {
        return <div style={{ minWidth: 100, minHeight: 100 }}></div>
    }

    return layer
}

ThemeVariants.displayName = "Theme Variants"

addPropertyControls(ThemeVariants, {
    light: {
        type: ControlType.ComponentInstance,
        title: "Light",
    },
    dark: {
        type: ControlType.ComponentInstance,
        title: "Dark",
    },
})
